<template>
  <b-card
    no-body
    class="w-full h-[calc(100vh-160px)] min-h-[400px]"
  >
    <b-card-header>
      <h4>
        Click on a location and start purchasing a service of your choice.
      </h4>
    </b-card-header>
    <b-card-body>
      <GmapMap
        ref="gmap"
        :center="center"
        :zoom="zoom"
        class="w-full h-full"
        :options="{
          styles: mapStyles,
          streetViewControl: false,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE,
            ],
          },
        }"
        @zoom_changed="zoomChanged"
        @bounds_changed="boundsChanged"
      >
        <GmapMarker
          v-for="(m, index) in markers"
          :key="index"
          :position="m.position"
          :label="m.label"
          :clickable="true"
          :icon="icon"
          @mouseover="toggleInfoWindow(m, index)"
          @mouseout="infoWindowOpen = false"
          @click="openSideBar(m, index)"
        />
        <!-- <GmapInfoWindow
          :options="infoWindowOptions"
          :position="infoWindowPosition"
          :opened="infoWindowOpen"
          @closeclick="infoWindowOpen = false"
        /> -->
      </GmapMap>
    </b-card-body>
  </b-card>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue'
import {
  ref,
  watch,
  computed,
  onUnmounted,
  onMounted,
} from '@vue/composition-api'
import store from '@/store'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import {
  BCard,
  BCardBody,
  BCardText,
  BAvatar,
  BCardHeader,
} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BCardBody,
    BCardHeader,
  },
  setup(props, { root: $this, emit }) {
    const gmap = ref(null)
    const google = computed(() => getGoogleMapsAPI())
    const center = ref({ lat: 7.838906732865886, lng: 80.67792153757149 })
    const zoom = ref(7.9)

    const mapStyles = ref([
      {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#f3f4f4',
          },
          {
            saturation: -84,
          },
          {
            lightness: 59,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [
          {
            hue: '#ffffff',
          },
          {
            saturation: -100,
          },
          {
            lightness: 100,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#83cead',
          },
          {
            saturation: 1,
          },
          {
            lightness: -15,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi.school',
        elementType: 'all',
        stylers: [
          {
            hue: '#d7e4e4',
          },
          {
            saturation: -60,
          },
          {
            lightness: 23,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#ffffff',
          },
          {
            saturation: -100,
          },
          {
            lightness: 100,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            hue: '#ff0000',
          },
          {
            saturation: -100,
          },
          {
            lightness: 26,
          },
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#ffcc00',
          },
          {
            saturation: 100,
          },
          {
            lightness: -22,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
          {
            hue: '#ffcc00',
          },
          {
            saturation: 100,
          },
          {
            lightness: -35,
          },
          {
            visibility: 'simplified',
          },
        ],
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            hue: '#00a9ff',
          },
          {
            saturation: 55,
          },
          {
            lightness: -6,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
          {
            hue: '#7fc8ed',
          },
          {
            saturation: 55,
          },
          {
            lightness: -6,
          },
          {
            visibility: 'off',
          },
        ],
      },
    ])

    const map = ref(null)
    const markers = ref([])
    const locations = ref([])
    const icon = ref(null)
    const dataForLocations = ref(null)
    // const icons = ref(appIcons)

    const infoWindowOpen = ref(false)
    const infoWindowPosition = ref(null)
    const infoWindowOptions = ref({
      content: '',
      // optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 15,
        height: -5,
      },
    })
    const currentMarkerIdx = ref(null)

    const zoomChanged = zoom => {}
    const boundsChanged = event => {}

    const _renderInfo = location => ''
    const _getLocations = () => store.dispatch('app-map/fetchLocations', {})
    // const _getData = () => store.dispatch('app-map/fetchDataForLocations', {})

    const _setMarkers = () => {
      markers.value = _.map(locations.value, item => {
        const infoText = _renderInfo(item)
        return {
          position: {
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          },
          infoText,
          label: {
            text: item.name,
            className: 'app-marker-label',
          },
        }
      })
    }

    const drawMarkers = () => new Promise((resolve, reject) => {
      Promise.all([_getLocations(), gmap.value.$mapPromise])
        .then(values => {
          locations.value = values[0]
          map.value = values[1]

          _setMarkers()
          resolve(locations)
        })
        .catch(error => {
          reject(error)
        })
    })

    const toggleInfoWindow = (marker, idx) => {
      infoWindowPosition.value = marker.position
      infoWindowOptions.value.content = marker.infoText

      if (currentMarkerIdx.value == idx) {
        infoWindowOpen.value = !infoWindowOpen.value
      } else {
        infoWindowOpen.value = true
        currentMarkerIdx.value = idx
      }
    }

    $this.$gmapApiPromiseLazy().then(() => {
      Promise.all([drawMarkers()])
        .then(values => {
          _setMarkers()
        })
        .catch(() => {})

      icon.value = {
        url: require('@/assets/images/icons/tower.png'),
        scaledSize: new google.value.maps.Size(36, 36), // scaled size
        origin: new google.value.maps.Point(0, 0), // origin
        anchor: new google.value.maps.Point(0, 0), // anchor
      }
    })

    const openSideBar = (marker, index) => {
      $this.$root.$emit('location-picked', locations.value[index])
    }

    return {
      center,
      zoom,
      mapStyles,

      markers,
      icon,

      infoWindowOpen,
      infoWindowPosition,
      infoWindowOptions,

      toggleInfoWindow,

      google,
      gmap,

      zoomChanged,
      boundsChanged,

      openSideBar,
    }
  },
}
</script>

<style>
.app-marker-label {
  position: absolute;
  color: #1a73e8 !important;
  white-space: break-spaces;
  left: -120px;
  text-shadow: -1px 0px 1px black;
  width: 100px;
  text-align: right;
}

.app-map-popup-table tr:nth-of-type(even) {
  background: #ebe9f1;
}

.app-map-popup-table tr td {
  padding: 0.2rem;
}
</style>
