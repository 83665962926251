<template>
  <div>
    <location-google-map />
    <place-order-side-bar />
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import PlaceOrderSideBar from './PlaceOrderSideBar.vue'
import LocationGoogleMap from './LocationGoogleMap.vue'
import mapStoreModule from './mapStoreModule'

export default {
  components: {
    LocationGoogleMap,
    PlaceOrderSideBar,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-map'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, mapStoreModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })
  },
}
</script>
