import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import _ from 'lodash'
import moment from "moment-timezone";

moment.locale("en");
moment.tz("UTC");

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSideBar() {

    const toast = useToast()

    const waiting = ref(false);

    const submitForm = (data) => {
        data.location = data.location.id;

        waiting.value = true;
        return new Promise((resolve, reject) => {
            store.dispatch(`app-map/createOrder`, data)
                .then(({ data: { results } }) => {
                    localStorage.setItem('token', JSON.stringify(results.token));
                    localStorage.setItem('expired_at', JSON.stringify(results.expired_at));
                    localStorage.setItem('key', JSON.stringify(results.key));
                    resolve(results);
                    waiting.value = false;
                })
                .catch(({ response }) => {
                    reject(response);
                    let message = 'Failed to create an order';
                    if (response.hasOwnProperty('status') && response.status == 400) {
                        message = response.data.message;
                    }
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    },
                        {
                            position: 'top-center',
                        })

                    waiting.value = false;

                })
        });
    }

    return {
        submitForm,
        waiting,
    }
}