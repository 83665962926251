import axios from '@axios'

export default {
  namespaced: true,
  state: {
    locations: null,
  },
  getters: {},
  mutations: {
    UPDATE_LOCATIONS(state, payload) {
      state.locations = payload;
    },
  },
  actions: {
    fetchLocations({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/locations')
          .then(({ data: { results } }) => {
            commit('UPDATE_LOCATIONS', results)
            resolve(results)
          })
          .catch(error => reject(error))
      })
    },
    createOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/orders/create', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
