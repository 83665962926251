var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"w-full h-[calc(100vh-160px)] min-h-[400px]",attrs:{"no-body":""}},[_c('b-card-header',[_c('h4',[_vm._v(" Click on a location and start purchasing a service of your choice. ")])]),_c('b-card-body',[_c('GmapMap',{ref:"gmap",staticClass:"w-full h-full",attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
        styles: _vm.mapStyles,
        streetViewControl: false,
        mapTypeControlOptions: {
          mapTypeIds: [
            _vm.google.maps.MapTypeId.ROADMAP,
            _vm.google.maps.MapTypeId.SATELLITE ],
        },
      }},on:{"zoom_changed":_vm.zoomChanged,"bounds_changed":_vm.boundsChanged}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"label":m.label,"clickable":true,"icon":_vm.icon},on:{"mouseover":function($event){return _vm.toggleInfoWindow(m, index)},"mouseout":function($event){_vm.infoWindowOpen = false},"click":function($event){return _vm.openSideBar(m, index)}}})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }