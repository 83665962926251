<template>
  <div>
    <b-sidebar
      v-model="sideBarModel"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-black">
            Place Order
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <order-form
          v-if="location"
          :location="location"
          :key="location.id"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BSidebar, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ref,
  watch,
  computed,
  onUnmounted,
  onMounted,
} from '@vue/composition-api'
import OrderForm from './OrderForm.vue'
import store from '@/store'

export default {
  components: {
    BSidebar,
    OrderForm,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup(props, { root: $this }) {
    const sideBarModel = ref(false)

    const location = ref(null)

    $this.$root.$on('location-picked', event => {
      sideBarModel.value = true

      location.value = event
    })

    return {
      sideBarModel,

      location,
    }
  },
}
</script>
